import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import { Layout, Listing, Wrapper, Title, ProductPanel } from '../components'
import website from '../../config/website'
import logo from '../../static/little-home.png';
import picture from '../../static/kettles-overview.jpg'
import Img from 'gatsby-image'
import Link from 'gatsby-link';
const Hero = styled.header`
  background-color: ${props => props.theme.colors.greyLight};
  display: flex;
  align-items: center;
`

const Button = styled.button`
    background: none;
    color: #ffffff!important;
    border-width: 0px!important;
    border-radius: 0px;
    font-size: 16px;
    font-weight: 700!important;
    background-color: #677076;
    padding: 20px;
    text-transform: capitalize;
    border-radius: 10px;
    cursor: pointer;
    &:hover {
        background-color: #3d5c70;
    }
    `

const HeroInner = styled(Wrapper)`
  padding-top: 13rem;
  padding-bottom: 13rem;
  h1 {
    margin-bottom: 2rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
`

const HeroText = styled.div`
  font-size: 1.4rem;
  line-height: 1.4;
  margin-bottom: 2rem;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    font-size: 1.4rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1.25rem;
  }
`

const Social = styled.ul`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin-left: 0;
  font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  li {
    display: inline;
    &:not([data-name='social-entry-0']) {
      margin-left: 2.5rem;
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        margin-left: 1.75rem;
      }
    }
    a {
      font-style: normal;
      color: ${props => props.theme.colors.greyDark};
      font-size: 1.333rem;
      font-weight: 600;
      &:hover,
      &:focus {
        color: ${props => props.theme.colors.primary};
        text-decoration: none;
      }
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        font-size: 1.2rem;
      }
    }
  }
`

const ProjectListing = styled.ul`
  list-style-type: none;
  margin-left: 0;
  margin-top: 4rem;
  li {
    margin-bottom: 1.45rem;
    a {
      font-size: 2.369rem;
      font-style: normal;
      color: ${props => props.theme.colors.black};
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        font-size: 1.777rem;
      }
    }
  }
`

const ProductHomeWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 2.2rem;
`;

const Image = styled.img`
  height: 250px;
`

const IndexWrapper = Wrapper.withComponent('main')

class Recommended extends Component {
  render() {
    const {
      data: { productCategories, social, posts, projects },
    } = this.props
    console.log(this.props);
    return (
      <Layout>
        <Hero> 
          <HeroInner>
            <h1>Recommended Products</h1>
            <HeroText>
            We have spent hundreds of hours researching various home appliances that bring value into your lives. Its important to remember that these items aren't necessarily
the highest-end in the market. 
            </HeroText>
            <HeroText>
            Instead, these appliances provide values at all kind of price ranges. The aim to to transform your home into something a little more practical and a lot more cosy.
            </HeroText>
          {console.log(productCategories.edges)}
            <ProductHomeWrapper>
            {
             productCategories.edges.map((p, index) => (
               <ProductPanel product={p} />
             ))
            }
          </ProductHomeWrapper>
          
          </HeroInner>
        </Hero>
        {/* <IndexWrapper id={website.skipNavId} style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
          <Title style={{ marginTop: '4rem' }}>Recent posts</Title>
          <Listing posts={posts.edges} />
          <Title style={{ marginTop: '8rem' }}>Recent projects</Title>
        </IndexWrapper> */}
      </Layout>
    )
  }
}

export default Recommended

Recommended.propTypes = {
  data: PropTypes.shape({
    posts: PropTypes.object.isRequired,
  }).isRequired,
}

export const pageQuery = graphql`
  query RecommendedQuery {
     productCategories: allPrismicProductCategory {
        edges {
          node {
            data {
              title {
                text
              }
              goTo {
                text
              }
              category_image {
                localFile {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
                }
              }
            }
          }
        } 
  }
}
`


